<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center mb-4">
                <div style="width: 60%">
                    <b-input-group>
                        <b-form-input
                            id="filter-input"
                            type="text"
                            placeholder="Buscar registros"
                            class="bg-background-input"
                            v-model="search"
                            @keydown.enter="searchData"
                            @input="(value) => !value && searchData()"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button
                                variant="background-input"
                                class="text-blue-active d-flex align-items-center"
                                @click=";(search = ''), searchData()"
                            >
                                <feather type="search" size="1.1rem"></feather>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="ml-auto mt-3 mt-md-0">
                    <b-button
                        variant="primary"
                        size="md"
                        class="d-flex align-items-center mt-4 mt-sm-0"
                        @click="addRegister"
                        v-b-popover.hover.top="'Presione alt+a o option+a'"
                        :disabled="!permisos.includes('role-crear')"
                    >
                        <feather
                            type="plus"
                            size="1rem"
                            class="mr-2 blue-active-text"
                        ></feather>
                        Nuevo Registro
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="table-responsive mt-3" v-hotkey="keymap">
                    <b-table
                        :fields="fields"
                        :items="roles"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        :head-variant="Theme"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(tipo)="data">
                            <span
                                :class="`badge bg-light-success ${
                                    Theme === 'dark' && 'text-gray'
                                }`"
                            >
                                <feather
                                    type="hash"
                                    size="0.8rem"
                                    class="mr-1"
                                />
                                {{ data.value }}
                            </span>
                        </template>
                        <template #cell(descripcion)="data">
                            <span>
                                {{ data.value }}
                            </span>
                        </template>
                        <template #cell(acciones)="data">
                            <feather
                                type="edit"
                                role="button"
                                class="mr-2"
                                v-if="permisos.includes('role-editar')"
                                @click="
                                    $router.push({
                                        name: 'Editar rol',
                                        params: { rol_id: data.item.id }
                                    })
                                "
                            ></feather>
                            <feather
                                type="edit"
                                role="button"
                                class="mr-2 text-secondary"
                                v-else
                            ></feather>
                            <feather
                                type="trash-2"
                                role="button"
                                v-if="permisos.includes('role-eliminar')"
                                @click="showConfirm(data.item)"
                            ></feather>
                            <feather
                                type="trash-2"
                                role="button"
                                class="text-secondary"
                                v-else
                            ></feather>
                            <feather
                                type="eye"
                                role="button"
                                class="ml-2"
                                @click="
                                    $router.push({
                                        name: 'Ver rol',
                                        params: { rol_id: data.item.id }
                                    })
                                "
                            ></feather>
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            @change="getRoles('', 1, $event)"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            @input="getRoles('', $event, pagination.perPage)"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div
                class="d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"
            >
                <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('role-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Nuevo Registro
                </b-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState } from 'vuex'

const { API } = services

export default {
    name: 'MainRoles',
    mounted() {
        this.getRoles()
    },
    data: () => ({
        fields: [
            { key: 'tipo', label: 'Tipo de rol' },
            { key: 'descripcion', label: 'Descripción' },
            { key: 'acciones', label: 'Acciones' }
        ],
        roles: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        search: ''
    }),
    methods: {
        showLog(data) {
            console.log(data)
        },
        searchData() {
            if (this.search.length >= 3) {
                this.getRoles(this.search)
            } else if (!this.search) {
                this.getRoles()
            }
        },
        showConfirm({ id, descripcion }) {
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar el rol "${descripcion}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteRol(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteRol(id) {
            API.POST({
                url: 'seguridad/role/crud',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'DELETE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.getRoles()
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Eliminado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getRoles(term = '', page = 1, limit = this.pagination.perPage) {
            API.POST({
                url: 'seguridad/role/query',
                data: {
                    p_datajson: {
                        page,
                        term,
                        limit,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.roles = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size || 10,
                            currentPage: number || 1
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        addRegister() {
            this.$router.push('/home/general/seguridad/roles/crear-rol')
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 27 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getSedes']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
